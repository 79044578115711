<template>
<div>
    <b-field label="API Token">
        <textarea ref="textarea" class="input is-medium" id="token" v-model="this.token" disabled></textarea>
    </b-field>
    <div v-if = this.token>
        <b-button @click="copyToClipboard()" type="is-primary">Copy</b-button>
        <b-button id="regenToken" @click="confirmRegenerateAPIToken()" type="is-primary">Regenerate API Token</b-button>
        <b-button disabled class="hidden" id="genToken" @click="generateAPIToken()" type="is-primary">Create API Token</b-button>
    </div>
    <div v-else>
        <b-button @click="copyToClipboard()" type="is-primary">Copy</b-button>
        <b-button disabled class="hidden" id="regenToken" @click="confirmRegenerateAPIToken()" type="is-primary">Regenerate API Token</b-button>
        <b-button id="genToken" @click="generateAPIToken()" type="is-primary">Create API Token</b-button>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
    props: ['api_bearer_token'],
    data(){
        return {
            token: this.api_bearer_token,
        }
    },
    methods: {
        //this will be swapped out for the token generation api
        flashMessage(message, type, duration = 8000) {
            this.$buefy.toast.open({
                container: 'toast-container',
                message: message,
                type: type,
                duration: duration,
                position: 'is-bottom',
            })
        },
        generateJwt() {
            this.flashMessage("Please wait", "is-danger");
            //generate or update JWT
            axios.get('/generateJWT')
            .then(response => {
                this.$buefy.dialog.confirm({
                    title: "New API Token Created",
                    message:"Remember to copy your new API Token.",
                    onCancel: () => { return false }
                });
                this.token = response.data.new_token;
                return true;
            })
            .catch(error => {
                this.flashMessage('Error generating token.', 'is-danger');
                return false;
            })
        },
        copyToClipboard() {
            // if field isn't empty
            if (!$('#token').val()) {
                this.flashMessage("Nothing to copy", "is-danger");
                return;
            }
            const textField = document.createElement('textarea');
            textField.innerText = $('#token').val();
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            this.flashMessage("Copied", "is-success");
        },
        generateAPIToken() {
            const token = this.generateJwt();
            if(token){
                $('#genToken').addClass('hidden');
                $('#genToken').prop('disabled', true);
                $('#regenToken').removeClass('hidden');
                $('#regenToken').prop('disabled', false);
            }
            this.resizeTextarea();
        },
        confirmRegenerateAPIToken() {
            this.$buefy.dialog.confirm({
                title: 'Warning!',
                message: 'Regenerating your API Token effects all members of your group and make the old key invalid. You will have to update any application that used the old key.',
                type: 'is-danger',
                ariaRole: 'alertdialog',
                ariaModal: true,
                onConfirm: () => this.generateJwt()
            });
        },
        resizeTextarea() {
            this.$refs.textarea.style.height = "auto";
            let newHeight = this.$refs.textarea.scrollHeight;
            this.$refs.textarea.style.height = `${newHeight}px`;
        }
    },
    watch: {
        // void
    },
    computed: {
        console: () => console,
        window: () => window,
    },
    mounted() {
        //this.getToken();
        this.resizeTextarea();
    },
    beforeDestroy() {
        // void
    },
};
</script>

<style scoped>
 #big-box {
     width: 100%;
     margin-top: 30px;
     padding:5px;
     background: white;
     color: black;
 }
</style>