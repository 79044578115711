<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <div class="title"></div>
            </div>
            <div class="column">
                <div class="is-pulled-right">
                    <div class="button is-medium is-primary" @click="refresh">Refresh</div>
                </div>
                <div class="is-pulled-right">
                    <JsonExcel
                        class="button is-medium is-primary"
                        :data="excelExport"
                        worksheet="My Worksheet"
                        type="xls"
                        name="claimsExport.xls"
                    >
                        Download Excel
                    </JsonExcel>
                </div>
            </div>
        </div>
        <div class="columns is-tablet">
            <div class='column'>

            </div>
            <b-field class='column is-2' label="Claim Date From:">
                <b-input v-model="search_query.from" type="date" name="from" id="from"/>
            </b-field>
            <b-field class='column is-2' label="Claim Date To:">
                <b-input v-model="search_query.to" type="date" name="to" id="to"/>
            </b-field>
        </div>
        <div class="columns is-tablet">
            <div class="column">
                <b-field label="Search by name or procedure code">
                    <b-input v-model="search_query.name" placeholder="Patient Name" type="search" icon="search" icon-pack="fas"/>
                </b-field>
            </div>
            <div class="column is-narrow">
<!--                <div class="label">Claim Status</div>f
                <b-field>
                    <b-radio-button v-model="search_query.status"
                                    native-value="All"
                                    type="is-success">
                        All
                    </b-radio-button>

                    <b-radio-button v-model="search_query.status"
                                    native-value="Created"
                                    type="is-success">
                        Created
                    </b-radio-button>

                    <b-radio-button v-model="search_query.status"
                                    native-value="Progress"
                                    type="is-success">
                        In-Progress
                    </b-radio-button>

                    <b-radio-button v-model="search_query.status"
                                    native-value="Completed"
                                    type="is-success">
                        Completed
                    </b-radio-button>
                </b-field>-->
            </div>
        </div>
        <div v-if="this.loaded === undefined || this.loaded === false" class="columns is-centered">
            <div class="column is-narrow">
                <div class="has-text-centered">
                    <div class="spring-spinner">
                        <div class="spring-spinner-part top">
                            <div class="spring-spinner-rotator"></div>
                        </div>
                        <div class="spring-spinner-part bottom">
                            <div class="spring-spinner-rotator"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="this.loaded">
            <b-table :data="filter"
                     :paginated="true"
                     :per-page="perPage"
                     :current-page="currentPage"
                     default-sort="updated_at"
                     default-sort-direction="desc"
                     hoverable>
                <b-table-column
                    field="id"
                    label="ID"
                    v-slot="props"
                >
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column
                    field="mrn"
                    label="MRN"
                    v-slot="props"
                >
                    {{ props.row.data.mrn }}
                </b-table-column>

                <b-table-column
                    field="patname"
                    label="Name"
                    v-slot="props"
                >
                    {{ props.row.data.firstname }} {{ props.row.data.lastname }}
                </b-table-column>

                <b-table-column
                    field="code"
                    label="Procedure Code"
                    v-slot="props"
                >
                    {{ props.row.data.code }}
                </b-table-column>

                <b-table-column
                    field="created_at"
                    label="Claim Date"
                    centered
                    sortable
                    v-slot="props"
                >
                    {{ props.row.created_at }}
                </b-table-column>

                <b-table-column
                    field="updated_at"
                    label="Date Modified"
                    centered
                    sortable
                    v-slot="props"
                >
                    {{ props.row.updated_at }}
                </b-table-column>

                <template #empty>
                    <p>No results found</p>
                </template>
            </b-table>
        </div>
        <b-modal id="claimPreview" :active.sync="claimPreview" full-screen>
            <div id="claimPreviewContainer" class="has-text-centered">
                <ClaimSelect :user="this.user" :claim="this.data[this.viewClaim]"></ClaimSelect>
            </div>
        </b-modal>
        <div v-if="claimPreview" id="claimPreviewCloseButton">
            <div class="button is-info" @click="hideClaimPreview()">
                Close
            </div>
        </div><b-modal :active.sync="openModal" full-screen scroll="keep">
            <div id="signatureForm">
                <div id="sigScreenOrientationMessage" ref="sigScreenOrientationMessage">
                    <div class="columns is-centered">
                        <div class="column is-half has-text-centered">
                            <article class="message is-danger is-medium">
                                <div class="message-header">
                                    <p>Turn Device</p>
                                </div>
                                <div class="message-body">
                                    This section is only viewable in landscape mode
                                </div>
                            </article>
                            <p><img width="70%" src="/images/rotate.png"></p>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import JsonExcel from "vue-json-excel";
import ClaimSelect from "./PrivateClaim/ClaimSelect";

let moment = require('moment');
let axios = require('axios');

export default {
    components: {
        JsonExcel, ClaimSelect
    },
    props: [],
    data() {
        return {
            openModal: false,
            current_editing: null, // JSON index of the currently edited row
            current_claim_id: null, // claim ID of the currently edited row
            // Component data
            loaded: true,
            currentPage: 1,
            data: [],
            user: {}, // dummy
            search_query: {
                name: '',
                from: '',
                to: '',
            },
            excelExport: [],

            // Printing data
            claimPreview: false,
            viewClaim: null,
        };
    },
    methods: {
        reloadRows() {
            let from = this.search_query.from;
            let to = this.search_query.to;
            this.loaded = false;
            axios.get('/getPrivateClaims', {params: {from, to}})
                .then(response => {
                    this.excelExport = response.data.excel;
                    this.data = response.data.results;
                    this.loaded = true;
                })
                .catch(error => {
                    this.flashMessage('Error reading claims', 'is-danger')
                })
        },
        refresh(){
            this.reloadRows();
        },
        showClaimPreview(claimID) {
            this.viewClaim = claimID
            this.claimPreview = true;
        },
        hideClaimPreview() {
            this.viewClaim = null
            this.claimPreview = false;
        },
        showOptions: function () {
            this.showDropDown = true;
        },
        flashMessage(message, type){
            this.$buefy.toast.open({
                container: 'toast-container',
                message: message,
                type: type,
                position: 'is-bottom',
            })
        },
    },
    watch: {
        'search_query.status': function (newStatus, oldStatus) {
            this.reloadRows();
        },
        'search_query.from': function (newFrom, oldFrom) {
            this.reloadRows();
        },
        'search_query.to': function (newTo, oldTo) {
            this.reloadRows();
        },
    },
    computed: {
        filter: function () {
            let name_re = new RegExp(this.search_query.name, 'i');
            let data = [];
            for (let i=0; i<this.data.length; i++) {
                if (this.data[i].data.firstname.match(name_re) || this.data[i].data.lastname.match(name_re)
                    || ('code' in this.data[i].data && this.data[i].data.code.toString().match(name_re)) ) {
                    data.push(this.data[i])
                }
            }
            return data
        },
        perPage: function(){
            if(this.$mq === 'sm'){
                return 2;
            } else if(this.$mq === 'md') {
                return 6;
            } else {
                return 10;
            }
        },
    },
    mounted() {
        this.reloadRows();
    }
};
</script>

<style scoped>
.container {
    margin-top: 50px;
}
@media only screen and (max-width: 800px) {
    .container {
        margin-top: 3px;
    }
}
.button {
    margin: 1px;
}
.box {
    margin-top: 10px;
}

.is-horizontal >>> .field-label{
    text-align: left;
}

.sigbox {
    height: 100vh;
    margin-bottom: auto;
}

.sigarea {
    border: 1px solid darkgrey;
    margin-bottom: 10px;
    border-radius: 10px;
}

#claimPreview {
    /*void*/
    overflow: hidden;
    padding: 0;
    margin: 0;
}

#claimPreviewContainer {
    width: 100%;
    height: 297mm;
    padding: 0;
    margin: 0;
}

#claimPreviewFrame {
    border-width: 1px;
    border-style: solid;
    border-color: grey;
    width: 210mm;
    height: 297mm;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

@media only screen and (max-width: 210mm) {
    #claimPreviewContainer {
        width: 210mm;
    }
}

#claimPreviewCloseButton {
    z-index: 100;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 3vh 3vw;
    margin: 1vh 1vw;
}

@media only screen and (orientation:portrait){
    #sigScreenOrientationMessage {
        display: block;
    }
    #sigbox {
        display: none;
    }
}

@media only screen and (orientation:landscape){
    #sigScreenOrientationMessage {
        display: none;
    }
    #sigbox {
        display: block;
    }
}
</style>
